export default class CalendarCell {
  constructor({ x, y, day, month, data = [] }) {
    this._x = x;
    this._y = y;
    this._day = day;
    this._data = data;
    this._month = month;
  }

  set data(val) {
    this._data = val;
  }

  /**
   * Get the x coordinate
   * @return {number} The x value
   */
  get x() {
    return this._x;
  }

  /**
   * Get the y coordinate
   * @return {number} The y value
   */
  get y() {
    return this._y;
  }

  /**
   * Get the day
   * @return {Date} The date of day
   */
  get day() {
    return this._day;
  }

  /**
   * Get the general month. Can be different from the day,
   * because component build by week for day month
   * @return {number} The general month
   */
  get month() {
    return this._month;
  }

  /**
   * Get the day
   * @return {Object|Array} The date of day
   */
  get data() {
    return this._data;
  }

  get isEmpty() {
    return this.data.length == 0 ? true : false;
  }
}
