<template>
  <article class="permission-calendar data-load" :class="{ 'sk-loading': showSpinner }">
    <sk-spinner v-if="showSpinner"></sk-spinner>

    <h4>{{ title }}</h4>
    <div class="card">
      <div class="card-title m-0 border-bottom">
        <a href="#" class="p-2 float-right btn-refresh" @click.prevent="refreshButtonClicked"
          ><i class="fas fa-sync-alt"></i
        ></a>
      </div>
      <div class="card-body p-0">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6">
            <suc-date-period-component
              :type="type"
              @[periodChangedEvent]="periodChanged"
              class="m-2"
            />
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <slot name="calendar-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col table-responsive">
        <table class="table text-center table-without-border">
          <thead>
            <tr>
              <td class="p-0">{{ $t("components.date.day_short[0]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[1]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[2]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[3]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[4]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[5]") }}</td>
              <td class="p-0">{{ $t("components.date.day_short[6]") }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, row_index) in preparedData" :key="row_index">
              <td class="p-0" v-for="(cell, cell_index) in row" :key="`${row_index}_${cell_index}`">
                <slot name="cell" :cell="cell"> {{ cell.day.getDate() }} </slot>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <slot name="foot"></slot>
          </tfoot>
        </table>
      </div>
    </div>
  </article>
</template>

<script>
import {
  addDays,
  differenceInCalendarDays,
  endOfWeek,
  getMonth,
  startOfWeek,
  isEqual,
} from "date-fns";
import { SucDatePeriodComponent } from "@/components/form";
import { SkSpinner } from "@/components";
import {
  DATE_PERIOD_WEEK,
  DATE_PERIOD_MONTH,
  SUC_DATE_PERIOD_CHANGED_EVENT,
} from "@/components/form";
import { CalendarCell, CellData, GET_CALENDAR_DATA } from "./index";

export default {
  components: {
    SucDatePeriodComponent,
    SkSpinner,
  },
  props: {
    type: {
      type: String,
      validator: function (val) {
        const types = [DATE_PERIOD_WEEK, DATE_PERIOD_MONTH];
        if (!types.includes(val)) {
          console.error("type shoud be one of", types);
          return false;
        }
        return true;
      },
      default: DATE_PERIOD_MONTH,
    },
    cellData: {
      type: Array,
      required: true,
      validator(values) {
        for (let value of values) {
          if (!(value instanceof CellData)) {
            console.error("data should include only CellData instance");
            return false;
          }
        }
        return true;
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  created() {},
  data() {
    return {
      calendar: [],
      cells: [],
      options: { weekStartsOn: 1 },
      /**
       * @var {Date}
       */
      start: null,
      /**
       * @var {Date}
       */
      end: null,
      currentMonth: 0,
      isBuilding: false,
    };
  },
  computed: {
    periodChangedEvent() {
      return SUC_DATE_PERIOD_CHANGED_EVENT;
    },
    showSpinner() {
      return this.isLoading || this.isBuilding;
    },
    preparedData() {
      let xy = [];
      for (let cell of this.cells) {
        if (!this.$isset(xy[cell.y])) {
          xy[cell.y] = [];
        }
        let dt = this.cellData.find((x) => isEqual(x.day, cell.day));
        cell.data = this.$isset(dt) ? dt.data : [];
        xy[cell.y][cell.x] = cell;
      }
      return xy;
    },
  },
  methods: {
    periodChanged({ start, end }) {
      this.currentMonth = getMonth(start);
      this.start = startOfWeek(start, this.options);
      this.end = endOfWeek(end, this.options);
      this.$emit(GET_CALENDAR_DATA, { start: this.start, end: this.end });
      this.build();
    },
    refreshButtonClicked() {
      this.$emit(GET_CALENDAR_DATA, { start: this.start, end: this.end });
    },
    build() {
      this.isBuilding = true;

      let cells = [];
      const diffDays = differenceInCalendarDays(this.end, this.start);

      for (let i = 0, x = 0, y = 0; i <= diffDays; i++) {
        let day = addDays(this.start, i);
        let cell = new CalendarCell({ x: x++, y, day, month: this.currentMonth });
        cells.push(cell);

        if (x == 7) {
          y++;
          x = 0;
        }
      }
      this.cells = cells;
      this.isBuilding = false;
    },
  },
};
</script>

<style lang="scss">
.btn-refresh {
  color: $color-gray !important;
}
</style>
