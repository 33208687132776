export default class CellData {
  /**
   * Cell data
   *
   * @param {Object} param
   * @param {Date} param.day Use this date to find coordinate in the component
   * @param {Object|Array} param.data Some data related with cell
   */
  constructor({ day, data }) {
    this._day = day;
    this._data = data;
  }

  set data(val) {
    this._data = val;
  }

  /**
   * Get the day
   * @return {Date} The date of day
   */
  get day() {
    return this._day;
  }

  /**
   * Get the day
   * @return {Object|Array} The date of day
   */
  get data() {
    return this._data;
  }
}
