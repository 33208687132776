<template>
  <div class="day m-1">
    <div class="day-header">
      <span class="day-number" :class="{ 'other-day': isCurrentMonth }">{{ dayDate }}</span>
    </div>
    <div class="day-body">
      <slot name="body" :cell="cell">
        <span>{{ dayDate }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
import { CalendarCell } from "./index";

export default {
  name: "SucCalendarItemComponent",
  props: {
    cell: {
      type: CalendarCell,
      required: true,
    },
  },
  computed: {
    dayDate() {
      return this.cell.day.getDate();
    },
    isCurrentMonth() {
      return this.cell.day.getMonth() != this.cell.month;
    },
  },
};
</script>

<style lang="scss" scoped>
.other-day {
  color: $color-gray-2;
}
</style>
